<template>
  <div class="d-flex flex-column">
    <v-row>
      <v-col cols="12" sm="10" md="9" lg="8" xl="7">
        <v-card class="pa-8 mb-4" color="surface">
          <v-list class="transparent pa-0">
            <v-list-item v-for="(item, i) in details" class="px-0" :key="i">
              <div class="d-flex flex-column flex-grow-1 justify-center">
                <div
                  class="d-flex justify-space-between flex-grow-1 align-center px-1"
                >
                  <span
                    class="text--body-3 font-weight-bold"
                    v-text="item.label"
                  />
                  <span class="text--body-3" v-text="item.value" />
                </div>
                <v-divider />
              </div>
            </v-list-item>
          </v-list>
        </v-card>

        <div class="d-flex align-center">
          <span class="mr-4 text-body-2 subtext--text">
            Comprovante escolar
          </span>

          <v-btn
            class="rounded-l-0 rounded-r-lg text-none"
            color="accent"
            target="_blank"
            :href="player.player.schoolCertificate"
          >
            <div v-if="player.player.schoolCertificate">
              <v-icon left>mdi-file</v-icon>
              Ver comprovante
            </div>

            <div v-else>Pendente</div>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  props: {
    player: {
      type: null,
      required: true,
    },
  },

  computed: {
    details() {
      return [
        {
          label: "Nome",
          value: `${this.player.firstName} ${this.player.lastName}`,
        },
        {
          label: "E-mail",
          value: this.player.email,
        },
        {
          label: "Telefone",
          value: `+${this.player.ddi} ${this.player.phone}`,
        },
        {
          label: "Nickname",
          value: this.player.player.nickname,
        },
        {
          label: "CPF",
          value: this.player.player.document,
        },
        {
          label: "Gênero",
          value: this.handleGenre(this.player.player.genre),
        },
        {
          label: "Escola",
          value: this.player.player.school
            ? this.player.player.school.name
            : this.player.player.schoolComplementName,
        },
      ];
    },
  },

  methods: {
    handleGenre(value) {
      switch (value) {
        case "F":
          return "Feminino";
        case "M":
          return "Masculino";
        case "O":
          return "Outro";
      }
    },

    formatDate,
  },
};
</script>

<style></style>
